import React, { useState } from 'react'
// import { Redirect } from 'react-router-dom'

import { firebaseApp } from '../base'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import Login from './index'
import ForgotPassword from './ForgotPassword'

import {
    Layout, Form, Input, Button,
    Typography, Alert
} from 'antd';

import {
    MailOutlined, LockOutlined, UserOutlined, PhoneOutlined
} from '@ant-design/icons'

import moment from 'moment'

const Signup = props => {

    console.log(props)
    const [user, setUser] = useState({
        firstname: '',
        name: '',
        phone: '',
        email: '',
        password: '',
        createdAt: moment().unix(),
    })
    const [forgot, setForgot] = useState(false)
    const [login, setLogin] = useState(false)

    const [alert, setAlert] = useState()

    const handleSubmit = () => {
        firebaseApp
            .auth()
            .createUserWithEmailAndPassword(user.email, user.password)
            .then(data => {
                const db = firebaseApp.firestore()
                db.collection("users").doc(data.user.uid).set({ user })
                props.setVisible(false) // Impossible de passer la function.... "props.signUp is not a function..."
                props.handleLogin()
            })
            .catch((err) => {
                setAlert(<Alert style={{ marginBotton: 5 }} message={err.message} type="error" />)
            })
    }

    const handleInput = e => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }

    const { Content } = Layout
    const { Title, Paragraph } = Typography

    if (forgot) {
        return <ForgotPassword />
    }
    if (login) {
        return <Login setVisible={v => props.setVisible(v)} />
    }
    return (
        <Layout className="layout">
            <Content>
                <Typography
                    style={{

                        borderRadius: 8, marginTop: '10vh', marginBottom: 64,
                        textAlign: 'center'
                    }}>
                    <Title level={1} style={{ fontSize: 32, marginBottom: 32 }}>
                        S'inscrire
                    </Title>
                    {alert}

                    <Form
                        onFinish={handleSubmit}
                        onFinishFailed={() => console.log('Echec de validation')}
                        className="login-form"
                    >
                        <Form.Item
                            name="Votre Prénom"
                            rules={[{ required: true }]}
                        >
                            <Input
                                prefix={<UserOutlined />}
                                type="text"
                                size="large"
                                name="firstname"
                                placeholder=" Votre Prénom"
                                onChange={handleInput}
                            />
                        </Form.Item>

                        <Form.Item
                            name="Votre Nom"
                            rules={[{ required: true }]}
                        >
                            <Input
                                prefix={<UserOutlined />}
                                type="text"
                                size="large"
                                name="name"
                                placeholder=" Votre Nom"
                                onChange={handleInput}
                            />
                        </Form.Item>

                        <Form.Item
                            name="Votre Téléphone"
                            rules={[{ required: true }]}
                        >
                            <Input
                                prefix={<PhoneOutlined />}
                                type="number"
                                size="large"
                                name="phone"
                                placeholder="Votre Téléphone"
                                onChange={handleInput}
                            />
                        </Form.Item>

                        <Form.Item
                            name="Vore Email"
                            rules={[{ required: true }]}
                        >
                            <Input
                                prefix={<MailOutlined />}
                                type="email"
                                size="large"
                                name="email"
                                placeholder=" Votre Email"
                                onChange={handleInput}
                            />

                        </Form.Item>

                        <Form.Item
                            name="Votre Mot de passe"
                            rules={[{ required: true }]}
                        >

                            <Input
                                prefix={<LockOutlined />}
                                size="large"
                                type="password"
                                name="password"
                                placeholder=" Votre mot de passe"
                                onChange={handleInput}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                shape="round" type="primary" htmlType="submit" className="login-form-button"
                            >
                                Confirmer
                            </Button>
                        </Form.Item>
                        <Paragraph>
                            <Button
                                type="link"
                                className="login-form-forgot"
                                onClick={() => setForgot(true)}
                            >
                                Mot de passe oublié ?
                                    </Button>
                            <Button
                                type="link"
                                className="signup-form"
                                onClick={() => setLogin(true)}
                            >
                                Vous avez déjà un compte, connectez-vous
                                    </Button>

                        </Paragraph>
                    </Form>
                </Typography>
            </Content>
        </Layout >
    )

}


export default Signup