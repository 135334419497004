import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Typography,
  Row,
  Select,
  ConfigProvider,
  DatePicker,
  Button,
  Col,
  Tooltip,
} from "antd";
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import firebase from "firebase/app";
import frFR from "antd/es/locale/fr_FR";
import moment from "moment";

const HandleReminder = (props) => {
  const [reminder, setReminder] = useState({
    date_vaccin: "",
    date_externe: "",
    frequence_externe: "",
    date_interne: "",
    frequence_interne: "",
    date_checkup: "",
    frequence_checkup: "",
  });

  const formRef = useRef();

  useEffect(() => {
    const loadReminder = () => {
      const db = firebase.firestore();
      db.collection("reminders")
        .doc(props.reminderId)
        .get()
        .then((snap) => {
          let reminder;
          reminder = snap.data();
          console.log(moment(reminder.reminder.date_vaccin));
          setReminder(reminder.reminder);
          // Initialise les champs avec les valeurs du state
          formRef.current.setFieldsValue({
            // date_vaccin: moment(reminder.reminder.date_vaccin),
            // date_externe: reminder.reminder.date_externe,
            frequence_externe: reminder.reminder.frequence_externe,
            // date_interne: moment(reminder.reminder.date_interne).toISOString(),
            frequence_interne: reminder.reminder.frequence_interne,
            // date_checkup: reminder.reminder.date_checkup,
            frequence_checkup: reminder.reminder.frequence_checkup,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    };
    if (props.reminderId) {
      loadReminder();
    }
  }, [props.reminderId]);

  const { Paragraph, Title } = Typography;
  const { Option } = Select;

  const frequenceArray = [
    "Toutes les semaines",
    "Toutes les 2 semaines",
    "Toutes les 3 semaines",
    "Tous les mois",
    "Tous les 2 mois",
    "Tous les 3 mois",
    "Tous les 4 mois",
    "Tous les 5 mois",
    "Tous les 6 mois",
    "Tous les 18 mois",
    "Tous les ans",
    "Tous les 2 ans",
    "Tous les 3 ans",
  ];

  return (
    <Form ref={formRef} onFinish={() => props.addReminder(reminder)}>
      <Typography>
        <Title style={{ marginTop: 48 }} level={2}>
          {" "}
          Ses rappels
        </Title>
        {/* <Paragraph style={{ textAlign: "right", fontSize: 12 }}>
          <span style={{ color: "red" }}>*</span> Champs obligatoires
        </Paragraph> */}
        <Row justify="start" className="form-item">
          <Paragraph
            style={{
              width: "100%",
              fontSize: 18,
              fontWeight: 500,
              marginRight: 12,
              marginTop: 32,
            }}
          >
            Rappel de vaccination
          </Paragraph>

          <Row justify="start">
            <Form.Item
              label="Date du dernier vaccin"
              name="date_vaccin"
              rules={[
                {
                  // required: true,
                  message: "Veuillez indiquer la date du dernier vaccin",
                },
              ]}
            >
              <ConfigProvider locale={frFR}>
                <DatePicker
                  style={{ width: "100%" }}
                  format="ddd DD MMM YY"
                  placeholder="Date du dernier vaccin"
                  value={
                    reminder.date_vaccin ? moment(reminder.date_vaccin) : ""
                  }
                  onSelect={(e) =>
                    setReminder({
                      ...reminder,
                      date_vaccin: moment(e).toISOString(),
                    })
                  }
                />
              </ConfigProvider>

              <em>Le vaccin a lieu tous les ans</em>
            </Form.Item>
          </Row>
        </Row>

        <Row justify="start" className="form-item">
          <Paragraph
            style={{
              width: "100%",
              fontSize: 18,
              fontWeight: 500,
              marginTop: 32,
            }}
          >
            Rappel du traitement antiparasitaire externe
          </Paragraph>

          <Col xs={{ span: 24 }} md={{ span: 10 }}>
            <Form.Item
              label="Date du dernier traitement"
              name="date_externe"
              rules={[
                {
                  // required: true,
                  message: "Veuillez indiquer la date du dernier traitement",
                },
              ]}
            >
              <ConfigProvider locale={frFR}>
                <DatePicker
                  style={{ width: "100%" }}
                  format="ddd DD MMM YY"
                  placeholder="Date du dernier traitement"
                  value={
                    reminder.date_externe ? moment(reminder.date_externe) : ""
                  }
                  onSelect={(e) =>
                    setReminder({
                      ...reminder,
                      date_externe: moment(e).toISOString(),
                    })
                  }
                />
              </ConfigProvider>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
            <Form.Item
              label="Fréquence"
              name="frequence_externe"
              rules={[
                {
                  // required: true,
                  message: "Veuillez indiquer la fréquence de traitement",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                onSelect={(value) =>
                  setReminder({
                    ...reminder,
                    frequence_externe: value,
                  })
                }
                placeholder="Choisir la fréquence"
              >
                {frequenceArray.map((f) => (
                  <Option key={f} value={f}>
                    {f}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 0 }} md={{ span: 1  }}>
            <Tooltip title="Texte à venir">
              <QuestionCircleOutlined
                style={{
                  color: "#32b4df",
                  opacity: 0.7,
                  marginLeft: 12,
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </Col>
        </Row>

        <Row justify="start" className="form-item">
          <Paragraph
            style={{
              width: "100%",
              fontSize: 18,
              fontWeight: 500,
              marginTop: 32,
            }}
          >
            Rappel du traitement antiparasitaire interne (vermifuge)
          </Paragraph>
          <Col xs={{ span: 24 }} md={{ span: 10 }}>
            <Form.Item
              label="Date du dernier traitement"
              name="date_interne"
              rules={[
                {
                  // required: true,
                  message: "Veuillez indiquer la date du dernier traitement",
                },
              ]}
            >
              <ConfigProvider locale={frFR}>
                <DatePicker
                  style={{ width: "100%" }}
                  format="ddd DD MMM YY"
                  placeholder="Date du dernier traitement"
                  value={
                    reminder.date_interne ? moment(reminder.date_interne) : ""
                  }
                  onChange={(e) =>
                    setReminder({
                      ...reminder,
                      date_interne: moment(e).toISOString(),
                    })
                  }
                />
              </ConfigProvider>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
            <Form.Item
              label="Fréquence"
              name="frequence_interne"
              rules={[
                {
                  // required: true,
                  message: "Veuillez indiquer la fréquence de traitement",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                onSelect={(value) =>
                  setReminder({
                    ...reminder,
                    frequence_interne: value,
                  })
                }
                placeholder="Choisir la fréquence"
              >
                {frequenceArray.map((f) => (
                  <Option key={f} value={f}>
                    {f}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 0 }} md={{ span: 1  }}>
            <Tooltip title="Texte à venir">
              <QuestionCircleOutlined
                style={{
                  color: "#32b4df",
                  opacity: 0.7,
                  marginLeft: 12,
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </Col>
        </Row>

        <Row justify="start" className="form-item">
          <Paragraph
            style={{
              width: "100%",
              fontSize: 18,
              fontWeight: 500,
              marginTop: 32,
            }}
          >
            Rappel du check-up de santé
          </Paragraph>
          <Col xs={{ span: 24 }} md={{ span: 10 }}>
            <Form.Item
              label="Date du dernier traitement"
              name="date_checkup"
              rules={[
                {
                  // required: true,
                  message: "Veuillez indiquer la date du dernier traitement",
                },
              ]}
            >
              <ConfigProvider locale={frFR}>
                <DatePicker
                  style={{ width: "100%" }}
                  format="ddd DD MMM YY"
                  placeholder="Date du dernier traitement"
                  value={
                    reminder.date_checkup ? moment(reminder.date_checkup) : ""
                  }
                  onSelect={(e) =>
                    setReminder({
                      ...reminder,
                      date_checkup: moment(e).toISOString(),
                    })
                  }
                />
              </ConfigProvider>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
            <Form.Item
              label="Fréquence"
              name="frequence_checkup"
              rules={[
                {
                  // required: true,
                  message: "Veuillez indiquer la fréquence de traitement",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                onSelect={(value) =>
                  setReminder({
                    ...reminder,
                    frequence_checkup: value,
                  })
                }
                placeholder="Choisir la fréquence"
              >
                {frequenceArray.map((f) => (
                  <Option key={f} value={f}>
                    {f}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 0 }} md={{ span: 1  }}>
            <Tooltip title="Texte à venir">
              <QuestionCircleOutlined
                style={{
                  color: "#32b4df",
                  opacity: 0.7,
                  marginLeft: 12,
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </Col>
        </Row>
      </Typography>

      <Form.Item style={{ textAlign: "center", marginTop: 32 }}>
        <Button
          onClick={props.previousStep}
          type="link"
          style={{ opacity: 0.7 }}
        >
          <ArrowLeftOutlined /> Précédent
        </Button>
        <Button htmlType="submit" type="link">
          Suivant <ArrowRightOutlined />
        </Button>
      </Form.Item>
    </Form>
  );
};

export default HandleReminder;
