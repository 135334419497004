import React, { useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { Link } from "react-router-dom";
import Login from "./login";
import { Layout, Menu, Row, Col, Dropdown, Button, Modal } from "antd";
import {
  HomeOutlined,
  UserOutlined,
  DisconnectOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const Navbar = (props) => {

  const [visible, setVisible] = useState(false);

  const logout = async () => {
    await firebase.auth().signOut();
    props.setName("");
    props.setUserId("");
    props.setFirstName("");
  };

  const { Header } = Layout;

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Link to="/dashboard">
          <HomeOutlined style={{ marginRight: 3 }} /> Tableau de bord
        </Link>
      </Menu.Item>

      <Menu.Item key="2" onClick={logout}>
        <DisconnectOutlined />
        Déconnexion
      </Menu.Item>
    </Menu>
  );

  return (
    <Header style={{ zIndex: 100, width: "100%", position: "fixed" }}>
      <Row type="flex" align="middle">
        <Col span={5}>
          <Link to="/" style={{ fontSize: 18, lineHeight: 2 }}>
            <img
              width="190"
              src="https://pensebete-innovet.fr/png/logo.png"
              alt="Pense-Bête par Innovet"
            />
          </Link>
        </Col>
        <Col span={19}>
          {!props.userId ? (
            <Row type="flex" justify="end" align="middle">
              <div>
                <Button
                  shape="circle"
                  ghost
                  type="default"
                  onClick={() => setVisible(true)}
                >
                  <UserOutlined />
                </Button>
              </div>
            </Row>
          ) : (
            <Row type="flex" justify="end">
              <div>
                <Button
                  ghost
                  shape="circle"
                  style={{ marginRight: 12, marginLeft: 12 }}
                >
                  <Link to="/">
                    <PlusOutlined />
                  </Link>
                </Button>
              </div>
              <div>
                <Dropdown overlay={menu}>
                  <Button ghost shape="round">
                    <Link to="/dashboard">
                      {props.loading ? (
                        <LoadingOutlined />
                      ) : (
                        <div>
                          <HomeOutlined /> {props.name}
                        </div>
                      )}
                    </Link>
                  </Button>
                </Dropdown>
              </div>
            </Row>
          )}
        </Col>
      </Row>

      <Modal
        footer={false}
        onCancel={() => setVisible(false)}
        visible={visible}
      >
        <Login
          setVisible={setVisible}
          handleLogin={() => console.log("handleLogin")}
        />
      </Modal>
    </Header>

  );
};

export default Navbar;
