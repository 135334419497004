import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

import moment from 'moment'
// import { Link } from 'react-router-dom'

import {
    Table, Layout, Button, Typography, message
} from 'antd'
import { DeleteFilled } from '@ant-design/icons'

const AdminUser = () => {


    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            const db = firebase.firestore();
            const data = await db.collection("users").get();
            setUsers(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
            setLoading(false)
        };
        fetchData();
    }, [])

    const onDelete = id => {
        const db = firebase.firestore()
        db.collection('users').doc(id).delete()
        message.info("L'utilisateur a bien été supprimé ")
    }

    const adminBtn = id =>

        <Button type="default" size="small"
            onClick={() => onDelete(id)}
        >
            <DeleteFilled />
        </Button>
        

    const columns = [
        {
            title:'ID',
            dataIndex:'id',
        },
        {
            title: 'Nom',
            dataIndex: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Téléphone',
            dataIndex: 'phone',
        },
        {
            title: 'Inscription',
            dataIndex: 'createdAt',
        },
        {
            title: '',
            dataIndex: 'actions'
        }
    ]

    const userList = []

    if (users && users.length) {
        users.forEach((item, i) => {
            const { id } = item
            userList.push({
                key: i,
                id : item.id,
                name: item.user.firstname + " " + item.user.name,
                phone : item.user.phone,
                email: item.user.email,
                createdAt: moment.unix(item.user.createdAt).calendar(),
                actions: adminBtn(id)
            })
        })
    }
    console.log(userList)
    const { Content } = Layout
    const { Title } = Typography

    // const onUpdate = () => {
    //     const db = firebase.firestore()
    //     db.collection('spells').doc(spell.id).set({...spell, name})
    //   }


    return (

        <Content>
            <Typography style={{ width: '100%' }}>
                <Title level={4}>
                    Gestion des clients
                </Title>
                <Table columns={columns} dataSource={userList} loading={loading} />
            </Typography>
        </Content>

    );
}

export default AdminUser
