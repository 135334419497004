import React, { useState } from "react";
import "firebase/auth";
import { firebaseApp } from "../base";
import Signup from "./Signup";
import ForgotPassword from "./ForgotPassword";

import { Layout, Form, Input, Button, Typography, Alert } from "antd";

import { MailOutlined, LockOutlined, LoadingOutlined } from "@ant-design/icons";

const Login = (props) => {
  const [forgot, setForgot] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState();
  const [signup, setSignup] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    firebaseApp
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        props.handleLogin() 
        props.setVisible(false)
      })
      .catch((err) => {
        setAlert(<Alert message={err.message} type="error" />);
        setLoading(false);
      });
    
    // .then(props.history.push('/handle-report'))
  };

  const onMailChange = (event) => {
    setEmail(event.target.value);
  };
  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const { Title, Paragraph } = Typography;

  // if (uid) {
  //     return <Redirect to='/dashboard' />
  // }
  if (forgot) {
    return <ForgotPassword />;
  }
  if (signup) {
    return <Signup hello="hello" 
    handleLogin={props.handleLogin}
    setVisible={props.setVisible} />;
  }

  return (
    <Layout className="layout">
      <Typography
        style={{
          borderRadius: 8,
          marginTop: "10vh",
          marginBottom: 64,
          textAlign: "center",
        }}
      >
        <Title level={1} style={{ fontSize: 32, marginBottom: 32 }}>
          Se connecter
        </Title>
        {alert}
        <Form onFinish={handleSubmit} className="login-form">
          <Form.Item name="Votre Email" rules={[{ required: true }]}>
            <Input
              prefix={<MailOutlined />}
              type="email"
              size="large"
              placeholder="Votre Email"
              onChange={onMailChange}
            />
          </Form.Item>
          <Form.Item name="Votre Mot de passe" rules={[{ required: true }]}>
            <Input
              prefix={<LockOutlined />}
              size="large"
              type="password"
              placeholder=" Votre Mot de passe"
              onChange={onPasswordChange}
            />
          </Form.Item>
          <Paragraph>
            <Button
              shape="round"
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              {loading ? <LoadingOutlined /> : null}
              Valider
            </Button>
          </Paragraph>
          <Paragraph>
            <Button
              type="link"
              className="login-form-forgot"
              onClick={() => setForgot(true)}
              setVisible={props.setVisible}
            >
              Mot de passe oublié ?
            </Button>
            <Button
              type="link"
              className="signup-form"
              onClick={() => setSignup(true)}
            >
              Vous n'avez pas de compte, inscrivez-vous
            </Button>
          </Paragraph>
        </Form>
      </Typography>
    </Layout>
  );
};

export default Login;
