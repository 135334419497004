import React, { useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { Row, Col, Steps, Modal, message, Typography } from "antd";

import Login from "../login";

import HandleAnimal from "./HandleAnimal";
import HandleReminder from "./HandleReminder";
import Preview from "./Preview";
import ResultPage from "./ResultPage";
import moment from "moment";

// import { adminId } from '../constants'

const HandleForm = (props) => {
  const [state, setState] = useState({
    animal: {},
    reminder: {},
    createdAt: moment().toISOString(),
    updatedAt: "",
  });

  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState(0);

  const previousStep = () => {
    setCurrent(current - 1);
  };

  const addAnimal = (animal) => {
    const author = {
      uid: props.userId,
      name: props.name,
      firstName: props.firstName,
      email: props.email
    };
    setState({
      ...state,
      animal,
      author,
    });
    setCurrent(current + 1);
  };

  const addReminder = (reminder) => {
    setState({
      ...state,
      reminder,
    });
    setCurrent(current + 1);
  };

  const saveReminder = () => {
    if (props.userId) {
      const db = firebase.firestore();
      if (props.reminderId) {
        db.collection("reminders")
          .doc(props.reminderId)
          .set(state)
          .then(() => {
            setCurrent(current + 1);
          })
          .catch((err) => {
            message.error(err.message);
          });
      } else {
        db.collection("reminders")
          .add(state)
          .then(() => {
            setCurrent(current + 1);
          })
          .catch((err) => {
            message.error(err.message);
          });
      }
    } else {
      message.info(
        "Pour enregistrer ce pense-bête, veuillez vous connecter ou vous identifier"
      );
      setVisible(true);
    }
  };

  const handleLogin = () => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        console.log(user)
        const db = firebase.firestore();
        db.collection("users")
          .doc(user.uid)
          .get()
          .then((snap) => {
            const author = {
              uid: user.uid,
              name: snap.data().user.name,
              firstName: snap.data().user.firstname,
              email : snap.data().user.email
            };
            setState({
              ...state,
              author,
            });
            setVisible(false)
            message.success("Vous pouvez désormais valider votre pense-bête")

          })
          .catch((err) => {
            console.log("Impossible d'accéder à l'utilisateur " + err);
          });
      }
    });
  };

  const { status } = state;
  const { Step } = Steps;

  let successMsg;
  if (props.reminderId) {
    successMsg = "Le pense-bête a bien été mis à jour ! ";
  } else {
    successMsg = "Le pense-bête a bien été enregistré !";
  }

  let currentStep;

  const steps = (
    <Col xs={{ span: 0 }} md={{ span: 24 }}>
      <Steps
        // progressDot
        current={current}
        size="small"
        status={status}
        style={{ marginTop: 8 }}
      >
        <Step title="Mon animal" />
        <Step title="Ses rappels" />
        <Step title="Aperçu" />
        <Step title="Validation" />
      </Steps>
    </Col>
  );

  if (current === 0) {
    currentStep = (
      <HandleAnimal
        addAnimal={addAnimal}
        previousStep={previousStep}
        state={state.animal}
        reminderId={props.reminderId}
      />
    );
  } else if (current === 1) {
    currentStep = (
      <HandleReminder
        addReminder={addReminder}
        previousStep={() => previousStep(state)}
        reminderId={props.reminderId}
      />
    );
  } else if (current === 2) {
    currentStep = <Preview state={state} saveReminder={saveReminder} />;
  } else if (current === 3) {
    currentStep = <ResultPage successMsg={successMsg} />;
  }
  const { Paragraph, Title } = Typography;

  return (
    <Row id="main-form" style={{ margin: 32 }}>
      <Col
        xs={{ span: 24 }}
        md={{ span: 6, offset: 0 }}
        style={{ background: "white", padding: "24px 48px 16px 48px" }}
      >
        <Typography>
          <Title level={2} style={{ color: "#32b4df" }}>
            Créer <br />
            un pense-bête
          </Title>
          <Col xs={{ span: 0 }} md={{ span: 24 }}>
            <Paragraph style={{ fontSize: 14 }}>
              Grâce à ce formulaire, enregistrez les rappels vétérinaires de vos
              animaux.
              {/* et soyez notifiés par email dès qu'un traitement se révèle
            nécessaire à la bonne santé de votre compagnon. */}
              <br />
              <span style={{ color: "#C4C4C4" }}>v 1.1.0</span>
            </Paragraph>
          </Col>
        </Typography>
      </Col>
      <Col xs={{ span: 24, offset: 0 }} md={{ span: 16, offset: 1 }}>
        {steps}
        {currentStep}
      </Col>

      <Modal
        footer={false}
        onCancel={() => setVisible(false)}
        visible={visible}
      >
        <Login setVisible={setVisible} handleLogin={handleLogin} />
      </Modal>
    </Row>
  );
};

export default HandleForm;
