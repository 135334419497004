import React, { useState, useEffect, useRef } from "react";
import { Form, Typography, Row, Select, Input, Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

import firebase from "firebase/app";

const HandleAnimal = (props) => {
  const [animal, setAnimal] = useState({
    name: "",
    age: "",
    ageUnity: "",
    weight: "",
    weightUnity: "",
    species: "",
  });

  const formRef = useRef();

  useEffect(() => {
    const loadAnimal = () => {
      const db = firebase.firestore();
      db.collection("reminders")
        .doc(props.reminderId)
        .get()
        .then((snap) => {
          let reminder;
          reminder = snap.data();
          setAnimal(reminder.animal);
          // Initialise les champs avec les valeurs du state
          formRef.current.setFieldsValue({
            animalName: reminder.animal.name,
            species: reminder.animal.species,
            age: reminder.animal.age,
            ageUnity: reminder.animal.ageUnity,
            weight: reminder.animal.weight,
            weightUnity: reminder.animal.weightUnity,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    };
    if (props.reminderId) {
      loadAnimal();
    }

  }, [props.reminderId]);

 
  const { Paragraph, Title } = Typography;
  const { Option } = Select;

  return (
    <Form ref={formRef} onFinish={() => props.addAnimal(animal)}>
      <Typography>
        <Title
          style={{ marginTop: 48, fontFamily: "GB", color: "#02417a" }}
          level={3}
        >
          Mon animal
        </Title>

        <Row justify="start" align="middle" style={{ marginTop: 24 }}>
          <Paragraph style={{ fontSize: 18, fontWeight: 500, marginRight: 12 }}>
            Mon animal s'appelle
          </Paragraph>

          <Form.Item
            name="animalName"
            rules={[
              {
                required: true,
                message: "Veuillez indiquer son nom",
              },
            ]}
          >
            <Input
              placeholder="Nom de l'animal"
              style={{ width: "100%" }}
              onChange={(event) =>
                setAnimal({ ...animal, name: event.target.value })
              }
            />
          </Form.Item>

          <Paragraph
            style={{
              fontSize: 18,
              fontWeight: 500,
              marginLeft: 18,
              marginRight: 12,
            }}
          >
            c'est un
          </Paragraph>

          <Form.Item
            name="species"
            rules={[
              {
                required: true,
                message: "Veuillez sélectionner une espèce",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              onChange={(value) => setAnimal({ ...animal, species: value })}
              placeholder="Espèce"
            >
              <Option value="Chien">Chien</Option>
              <Option value="Chat">Chat</Option>
              <Option value="NAC">NAC</Option>
              <Option value="Poney">Poney</Option>
              <Option value="Cheval">Cheval</Option>
              <Option value="Âne">Âne</Option>
              <Option value="Autre">Autre</Option>
            </Select>
          </Form.Item>

          <Paragraph
            style={{
              fontSize: 18,
              fontWeight: 500,
              marginLeft: 18,
              marginRight: 12,
            }}
          >
            Il est âgé de
          </Paragraph>

          <Form.Item
            name="age"
            rules={[
              {
                required: true,
                message: "Veuillez indiquer son âge",
              },
            ]}
          >
            <Input
              placeholder="Saisir un nombre"
              style={{ width: "100%" }}
              type="number"
              onChange={(event) =>
                setAnimal({ ...animal, age: event.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            name="ageUnity"
            rules={[
              {
                required: true,
                message: "Veuillez indiquer son âge",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              onChange={(value) => setAnimal({ ...animal, ageUnity: value })}
              placeholder="ans"
            >
              <Option value="ans">ans</Option>
              <Option value="mois">mois</Option>
              <Option value="jours">jours</Option>
            </Select>
          </Form.Item>

          <Paragraph
            style={{
              fontSize: 18,
              fontWeight: 500,
              marginLeft: 18,
              marginRight: 12,
            }}
          >
            et pèse
          </Paragraph>

          <Form.Item
            name="weight"
            rules={[
              {
                required: true,
                message: "Veuillez indiquer son poids",
              },
            ]}
          >
            <Input
              placeholder="Saisir un nombre"
              style={{ width: "100%" }}
              type="number"
              onChange={(event) =>
                setAnimal({ ...animal, weight: event.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            name="weightUnity"
            rules={[
              {
                required: true,
                message: "Veuillez indiquer son poids",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              onChange={(value) => setAnimal({ ...animal, weightUnity: value })}
              placeholder="kilos"
            >
              <Option value="kilos">kilos</Option>
              <Option value="grammes">grammes</Option>
              <Option value="tonnes">tonnes</Option>
            </Select>
          </Form.Item>
        </Row>
      </Typography>
      <Form.Item style={{ textAlign: "center", marginTop: 32 }}>
        {/* <Button onClick={props.previousStep} type="link" style={{ opacity: 0.7 }}>
                    <ArrowLeftOutlined /> Précédent
            </Button> */}
        <Button htmlType="submit" type="link">
          Suivant <ArrowRightOutlined />
        </Button>
      </Form.Item>
    </Form>
  );
};

export default HandleAnimal;
