import Rebase from 're-base'
import firebase from 'firebase/app'

import 'firebase/database'
import { innovetBaseId } from './constants'


const firebaseApp = firebase.initializeApp(innovetBaseId)
  
const base = Rebase.createClass(firebaseApp.database())

export { firebaseApp }

export default base
