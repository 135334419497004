import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { Layout, Row, Typography, Menu } from "antd";
import { HomeOutlined, FileOutlined, UserOutlined } from "@ant-design/icons";

import AdminUser from "./AdminUser";
import AdminReminder from "./AdminReminder";

import { adminId } from "../constants";

const Dashboard = () => {
  const [userId, setUserId] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [active, setActive] = useState("home");
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        setUserId(user.uid);
        const db = firebase.firestore();
        setLoading(true);
        db.collection("users")
          .doc(user.uid)
          .get()
          .then((snap) => {
            setName(snap.data().user.name);
            setLoading(false);
          })
          .catch((err) => {
            console.log("Impossible d'accéder au vétérinaire " + err);
          });
      }
    });
  }, []);

  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const { Content, Sider } = Layout;
  const { Title } = Typography;

  let activeComponent, adminMenuItem;

  if (active === "AdminReminder") {
    activeComponent = <AdminReminder />;
  } else if (active === "AdminUser") {
    activeComponent = <AdminUser />;
  } else {
    activeComponent = (
      <Typography style={{ width: "100%" }}>
        <Title level={2} style={{ marginBottom: 48 }}>
          Bienvenue sur votre tableau de bord
        </Title>

        <AdminReminder />
      </Typography>
    );
  }
  if (userId === adminId) {
    adminMenuItem = (
      <Menu.Item key="3" onClick={() => setActive("AdminUser")}>
        <UserOutlined />
        <span>Utilisateurs</span>
      </Menu.Item>
    );
  }
  return (
    <Layout className="layout">
      <Navbar
        setUserId={setUserId}
        setName={setName}
        setFirstName={setFirstName}
        userId={userId}
        name={name}
        firstName={firstName}
        loading={loading}
      />
      <Layout style={{ marginTop: 62 }}>
        <Sider
          style={{ minHeight: "100vh" }}
          collapsible
          onCollapse={toggle}
          collapsed={collapsed}
        >
          <Menu defaultSelectedKeys={["1"]} theme="dark" mode="inline">
            <Menu.Item key="1" onClick={() => setActive("home")}>
              <HomeOutlined />
              <span>Accueil</span>
            </Menu.Item>
            <Menu.Item key="2" onClick={() => setActive("AdminReminder")}>
              <FileOutlined />
              <span>Pense-Bêtes</span>
            </Menu.Item>
            {adminMenuItem}
          </Menu>
        </Sider>
        <Content>
          <Row style={{ padding: 32 }}>{activeComponent}</Row>

          <Row></Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
