
import React from 'react'

import { Row, Col, Typography, Button } from 'antd'


const Welcome = props => {

    const { Title, Paragraph } = Typography
    // Si props.id, message personalisé
    return (
        <Row id="welcome-innovet">
            <div id="bg"></div>
            <Col span={17} offset={4}>
                <Typography style={{ textAlign: 'center' }}>
                    <img
                        width="400"
                        src="./png/logo-bleu.png" alt="Pense-Bête par Innovet" />
                        <br /><br /><br />
                    <Title level={1}>
                        <span style={{ padding: '1% 3%', color:'#32b4df' }}>
                            Bienvenue sur Pense-Bête
                        </span>
                    </Title>
                    <Paragraph style={{ padding: '3% 5% 3% 5%', fontSize: 20, color:'#02417a', fontFamily:'GB' }}>
                        Ne ratez plus aucun rappel pour votre compagnon !
                         <br />
                        {/* Utilisez les filtres disponibles, laissez-vous guider,  */}


                        <br />

                        <span style={{ fontSize: 14, color:'#02417a' }}>
                            [RGPD]Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce nec cursus libero. Donec tellus metus, pulvinar ac vulputate cursus, condimentum in lectus. <a href="//clinique-innovet.fr">Paramétrer</a>
                        </span>
                        <br /><br /><br />
                        <Button
                            onClick={props.onAccept}
                            size="large"
                            type="primary">
                            Créer un pense-bête</Button>

                    </Paragraph>
                </Typography>
            </Col>

        </Row>
    )
}

export default Welcome