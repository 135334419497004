import React from 'react'
import { Typography, Button, Form } from 'antd'
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons'

import moment from 'moment'

const Preview = props => {
   
    const { Paragraph, Title } = Typography

    return (

        <Typography style={{ marginTop: 48 }}>

            <Title level={2}> Mon {props.state.animal.species} {props.state.animal.name}</Title>

            <Paragraph style={{ fontSize: 18, marginTop: 24, fontWeight: 500 }}>
                mon animal s'appelle {props.state.animal.name}, c'est un {props.state.animal.species} âgé de {props.state.animal.age} {props.state.animal.ageUnity} et il pèse {props.state.animal.weight} {props.state.animal.weightUnity}.
                </Paragraph>

            <Title level={3}>Les Rappels de {props.state.animal.name}</Title>


            <Title level={4} style={{ fontSize: 18, marginTop: 24 }}>
                Dernière vaccination
            </Title>
            <Paragraph style={{ fontSize: 16,  fontWeight: 500 }}>
                {moment(props.state.reminder.date_vaccin).format("dddd DD MMMM YYYY")}
            </Paragraph>

            <Title level={4} style={{ fontSize: 18, marginTop: 24 }}>
                Dernier traitement antiparasitaire externe
            </Title>
            <Paragraph style={{ fontSize: 16, fontWeight: 400 }}>
                {moment(props.state.reminder.date_externe).format("dddd DD MMMM YYYY")}
                <br />
                Fréquence : {props.state.reminder.frequence_externe}
            </Paragraph>

            <Title level={4} style={{ fontSize: 18, marginTop: 24 }}>
                Dernier traitement antiparasitaire interne (vermifuge)
             </Title>
             <Paragraph style={{ fontSize: 16, fontWeight: 400 }}>
                {moment(props.state.reminder.date_interne).format("dddd DD MMMM YYYY")}
                <br />
                Fréquence : {props.state.reminder.frequence_interne}
            </Paragraph>

             <Title level={4} style={{ fontSize: 18, marginTop: 24 }}>
                Dernier checkup
             </Title>
             <Paragraph style={{ fontSize: 16, fontWeight: 400 }}>
                {moment(props.state.reminder.date_checkup).format("dddd DD MMMM YYYY")}
                <br />
                Fréquence : {props.state.reminder.frequence_checkup}
            </Paragraph>

            <Form.Item style={{ textAlign: 'center', marginTop: 32 }}>

                <Button onClick={props.previousStep} type="link" style={{ opacity: 0.7 }}>
                    <ArrowLeftOutlined /> Corriger
                </Button>

                <Button
                    onClick={props.saveReminder}
                    type="link">
                    Valider <ArrowRightOutlined />
                </Button>
            </Form.Item>

        </Typography>


    )
}

export default Preview