import React, { useState, useEffect } from "react";
import HandleForm from "./form/HandleForm";
import Welcome from "./Welcome";
import { Layout } from "antd";
import Navbar from "./Navbar";
import firebase from "firebase/app";

import "firebase/auth";
import "./static/App.css";
import "antd/dist/antd.css";
import "moment/locale/fr.js";

const App = (props) => {
  const [userId, setUserId] = useState("");
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false);

  const storedWelcome = () => (getWelcome ? JSON.parse(getWelcome) : false);
  const getWelcome = window.localStorage.getItem("welcome");
  const [welcome, setWelcome] = useState(storedWelcome());

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        setUserId(user.uid);
        const db = firebase.firestore();
        setLoading(true);
        db.collection("users")
          .doc(user.uid)
          .get()
          .then((snap) => {
            setName(snap.data().user.name);
            setFirstName(snap.data().user.firstname);
            setEmail(snap.data().user.email)
            setLoading(false);
          })
          .catch((err) => {
            console.log("Impossible d'accéder à l'utilisateur " + err);
          });
      }
    });
  }, []);

  function onAccept() {
    setWelcome(false);
    window.localStorage.setItem("welcome", "false");
  }

  const { Content } = Layout;

  return (
    <Layout className="pet-layout">
      {welcome ? (
        <Welcome onAccept={onAccept} />
      ) : (
        <Navbar
          setUserId={setUserId}
          setName={setName}
          setFirstName={setFirstName}
          userId={userId}
          name={name}
          loading={loading}
        />
      )}
      <Content style={{ marginTop: 64 }}>
        <HandleForm
          reminderId={
            props.match && props.match.params.id ? props.match.params.id : ""
          }
          userId={userId}
          name={name}
          email={email}
          firstName={firstName}
        />
      </Content>
    </Layout>
  );
};

export default App;
