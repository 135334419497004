import React, { useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import Login from "./index"
import { Layout, Form, Input, Button, Typography, message } from "antd";
import { MailOutlined } from "@ant-design/icons";

const ForgotPassword = props => {

  const [email, setEmail] = useState()
  const [login, setLogin] = useState()

  const onForgotPassword = (email) => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then((user) => {
        message.success(
          "Veuillez vérifier votre boite mail et cliquez sur le lien dans le mail que vous venez de recevoir"
        );
        console.log(user);
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const onMailChange = (event) => {
    setEmail(event.target.value);
  };

  
    const { Content } = Layout;
    const { Title, Paragraph } = Typography;

   
    if (login) {
        return <Login setVisible={v => props.setVisible(v)} />
    }
    
    return (
      <Layout className="layout">
        <Content>
          <Typography
            style={{
              borderRadius: 8,
              marginTop: "10vh",
              marginBottom: 64,
              textAlign: "center",
            }}
          >
            <Title level={1} style={{ fontSize: 32, marginBottom: 32 }}>
              Demande <br />
              de nouveau mot de passe
            </Title>

            <Form className="login-form">
              <Form.Item
                name="Renseignez Votre Email"
                rules={[{ required: true }]}
              >
                <Input
                  prefix={<MailOutlined />}
                  type="email"
                  size="large"
                  placeholder=" Votre Email"
                  onChange={onMailChange}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  shape="round"
                  type="primary"
                  onClick={() => onForgotPassword(email)}
                  className="login-form-button"
                >
                 
                  Obtenir un nouveau mot de passe
                </Button>
              </Form.Item>

              <Paragraph>
                <Button
                  type="link"
                  className="login-form-forgot"
                  onClick={() => setLogin(true)}
                >
                  J'ai réinitialisé mon mot de passe
                </Button>
              
              </Paragraph>
            </Form>
          </Typography>
        </Content>
      </Layout>
    );
  
}

export default ForgotPassword;
