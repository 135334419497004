import React from 'react'

import { Result, Row, Col } from 'antd'
import { SmileOutlined } from '@ant-design/icons'
import { TwitterShareButton, TwitterIcon, FacebookShareButton, FacebookIcon } from 'react-share'
import { sharedUrl, sharedTitle, hashtags } from '../constants'

const ResultPage = props => {

    const extra = <Row align="middle" justify="space-around">
        <Col span={6}>
            <TwitterShareButton
                url={sharedUrl}
                title={sharedTitle}
                hashtags={hashtags}
                style={{ alignItems: 'center', justifyContent: 'space-around', display: 'flex' }}
            >
                <TwitterIcon
                    size={32} round={true} style={{ marginRight: 8 }} /> Partager sur Twitter
        </TwitterShareButton>
        </Col>
        <Col span={6}>
            <FacebookShareButton
                url={sharedUrl}
                title={sharedTitle}
                hashtags={hashtags} style={{ alignItems: 'center', display: 'flex' }}
            >
                <FacebookIcon size={32} round={true} style={{ marginRight: 8 }} /> Partager sur Facebook
        </FacebookShareButton>
        </Col>
    </Row>

    return (
        <Result
            key="1"
            icon={<SmileOutlined />}
            title={props.successMsg}
            extra={extra}

        >

        </Result>
    )
}

export default ResultPage