export const adminId = "9EhBpJcRRaal8kfITy4mEqgSXZF2"

export const innovetBaseId = {
    apiKey: "AIzaSyCebm9vv3ir7fQD6ZhK2DL1GaFz1vJzGyg",
    authDomain: "petreminder-c0f4c.firebaseapp.com",
    databaseURL: "https://petreminder-c0f4c.firebaseio.com",
    projectId: "petreminder-c0f4c",
    storageBucket: "petreminder-c0f4c.appspot.com",
    messagingSenderId: "1004105226387",
    appId: "1:1004105226387:web:7781e9cf40ff70efcd1566"
}

export const sharedUrl = "clinique-innovet.fr"
export const sharedTitle = "J'ai créé un pense-bête sur Innovet"
export const hashtags = ['Innovet', 'Vétérinaire']

