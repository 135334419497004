import React, { useState, useEffect } from "react";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import moment from "moment";
import { Link } from "react-router-dom";

import { Table, Layout, Typography, Button, message, Modal } from "antd";
import {
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import { adminId } from "../constants";

const AdminReminder = () => {
  const [loading, setLoading] = useState(false);
  const [reminders, setReminders] = useState();

  const [uid, setUid] = useState();
  const { confirm } = Modal;
  const { Content } = Layout;
  const { Title } = Typography;

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        setUid(user.uid);
        try {
          setLoading(true);
          const db = firebase.firestore();
          let reminders;
          if (uid === adminId) {
            reminders = db.collection("reminders");
          } else {
            reminders = db
              .collection("reminders")
              .where("author.uid", "==", uid);
            console.log(reminders);
          }
          const data = await reminders.get();
          setReminders(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
          setLoading(false);
        } catch (err) {
          console.log(err.message);
        }
      }
    });
  }, [uid]);

  const onDelete = (id) => {
    const db = firebase.firestore();
    db.collection("reminders").doc(id).delete();
    message.info("Le pense-bête a bien été supprimé ");
  };

  const confirmDelete = (id) => {
    confirm({
      title: "Êtes-vous sûr de vouloir supprimer ce pense-bête",
      icon: <ExclamationCircleOutlined />,
      content:
        "En cliquant sur OK, le compte-rendu sera définitivement supprimé",
      onOk() {
        onDelete(id);
      },
      onCancel() {},
    });
  };

  const adminBtn = (id) => {
    if (uid === adminId) {
      return (
        <Button.Group>
          <Button type="default">
            <Link
              to={{
                pathname: "/handle-form/" + id,
                state: 2,
              }}
            >
              <EditFilled />
            </Link>
          </Button>

          <Button
            style={{ marginLeft: 8 }}
            type="default"
            onClick={() => confirmDelete(id)}
          >
            <DeleteFilled />
          </Button>
        </Button.Group>
      );
    } else
      return (
        <Button type="default">
          <Link
            to={{
              pathname: "/handle-form/" + id,
              state: 2,
            }}
          >
            <EditFilled />
          </Link>
        </Button>
      );
  };

  const columns = [
    {
      title: "Pense-Bête",
      dataIndex: "reminder",
    },
    {
      title: "Propriétaire",
      dataIndex: "owner",
    },
    {
      title: "Date d'édition",
      dataIndex: "createdAt",
    },
    // {
    //   title: "Mise à jour",
    //   dataIndex: "updatedAt",
    // },
    {
      title: "Actions",
      dataIndex: "actions",
    },
  ];

  const reminderList = [];

  if (reminders && reminders.length) {
    reminders.forEach((item, i) => {
      const { id } = item;

      reminderList.push({
        key: i,
        reminder: (
          <Link
            to={{
              pathname: "/handle-form/" + id,
              state: 2,
            }}
          >
            Pense-Bête de{" "}
            {item.animal.name +
              ", " +
              item.animal.species +
              ", âgé de " +
              item.animal.age +
              " " +
              item.animal.ageUnity}
          </Link>
        ),

        createdAt: moment(item.createdAt).calendar(),
        // updatedAt: item.updatedAt ? moment.unix(item.updatedAt).calendar() : "",
        owner: item.author
          ? item.author.firstName + " " + item.author.name
          : "",
        // owner :item.ownedBy,
        actions: adminBtn(id),
      });
    });
  }

  // const onUpdate = () => {
  //     const db = firebase.firestore()
  //     db.collection('spells').doc(spell.id).set({...spell, name})
  //   }

  return (
    <Content>
      <Typography style={{ width: "100%" }}>
        <Title level={4}>Gestion des Pense-Bêtes</Title>
        <Table columns={columns} dataSource={reminderList} loading={loading} />
      </Typography>
    </Content>
  );
};

export default AdminReminder;
