import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import PrivateRoute from './PrivateRoute'

import Login from './login/'
import Signup from './login/Signup'
import ForgotPassword from './login/ForgotPassword'

import Dashboard from './admin/Dashboard'

import HandleForm from './form/HandleForm'

import firebase from 'firebase/app'
import 'firebase/auth'

class Root extends Component {
  state = {
    uid: '',
    authenticated: false
  }
  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.handleAuth({ user },
          this.setState({ authenticated: true })
        )
      }
    })
  }
  handleAuth = async authData => {
    this.setState({
      uid: authData.user.uid
    })
  }
  render() {
    const { authenticated } = this.state
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={App} />
          <Route exact path='/signup' component={Signup} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/forgot' component={ForgotPassword} />
          <PrivateRoute exact path='/dashboard' component={Dashboard} authenticated={authenticated} />
         
          <Route exact path='/handle-form' component={HandleForm} 
          // authenticated={authenticated} 
          />
          <PrivateRoute exact path='/handle-form/:id' component={App} authenticated={authenticated} />

        </Switch>
      </BrowserRouter>
    )
  }
}


ReactDOM.render(<Root />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
